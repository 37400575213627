import gql from 'graphql-tag'

export const GetUsersGQL = gql`
  query ($filter: UserFilter) {
    getUsers(filter: $filter) {
      totalUsers
      users {
        id
        uid
        email
        phone
        company
        createdTime
        serviceLevel
        userBalance {
          id
          balance
        }
        config {
          marketplace {
            enabled
            fee
            extraCostOnBasePrice {
              providers {
                provider
                extraCost
                timeRange {
                  from
                  to
                  extraCost
                }
              }
            }
          }
          pinCode {
            enabled
            doNotSendWhenWindowStartOrPickupInCertainRange
          }
          podSignature {
            enabled
          }
          bulk4PL {
            enabled
            fee
            extraCostOnBasePrice {
              providers {
                provider
                extraCost
                timeRange {
                  from
                  to
                  extraCost
                }
              }
            }
            useVariablePriceToOfferDriver {
              enabled
            }
          }
          selfManaged {
            enabled
            fee
            groupCode
          }
          fixedPrice {
            bulkOrder {
              enabled
              fee
              pricePerStopSamePostalCode
            }
            standardOrder {
              enabled
              fee
              pricePerStopSamePostalCode
            }
          }
          priceMatrix {
            enabled
            default
            minPrice
            matrix {
              vehicleType
              deliveryType
              distanceFromPickup
              size
              pickupTime
              windowDuration
              totalDropoffs
              price
            }
          }
          qrCode {
            enabled
            isRequiredToScanWhenPickup
            type
          }
          batchingTiming {
            enabled
            nextdayCutoffTime
            samedayCutoffTime
            sameDayPickupTimeWithinXHours
            sameDayPickupTimeWithinXHoursIfFoundAnyDeliveries
          }
          providerMatching {
            enabled
            defaultExcludeProviders
            defaultIncludeProviders
            deliveryTypes {
              type
              excludeProviders
            }
          }
          geofencing {
            enabled
            maxDistance
          }
          placeOrderConfig {
            minimumTimeBetweenCurrentTimeAndPickupTimeInMin {
              enabled
              default {
                value
              }
              deliveryTypes {
                value
                type
              }
            }
            deliveryWindows {
              enabled
              allowedFixedWindows {
                start
                end
              }
            }
          }
          batchingOptions {
            batchingSplitWhenLate {
              enabled
              currentTimePassStartWindowThresholdInMin
              minimumLengthFromCurrentTimeToEndWindowToSplitInMin
            }
            batchingNextDay {
              enabled
            }
            batchingWithOtherMerchants {
              enabled
              merchants {
                id
                name
              }
            }
            flexiblePickups {
              enabled
              pickups {
                address1
                address2
                latitude
                longitude
                postalCode
                contactName
                contactNumber
              }
            }
          }
          rerace {
            reraceThreshold {
              enabled
              totalDeliveriesInRoute {
                from
                to
                valueInMinute
              }
            }
            reraceProviderNotMoreThanXValueCompareToLalamovePrice {
              enabled
              value
            }
          }
          sms {
            pricePerStop
            alwaysEnabledSms
            notifyOrderToRecipient {
              smsContent
            }
          }
          autoTip {
            enabled
            intervalXMinsAfterPickupTime
            tipAmountPerInterval
            maxTotalTipAmount
            chargeTipToDeliveryUnderFixedPrice
            autoTipStartFromPickupTimeMin
            perProviders {
              provider
              intervalMin
              tipAmountPerInterval
              maxTotalTipAmount
              autoTipStartFromPickupTimeMin
            }
          }
          antiStacking {
            enabled
            excludeProviders
          }
          providerApiKey {
            providers {
              name
              key
              secret
              enabled
            }
          }
          autoAssign {
            hyperLocal {
              enabled
            }
          }
          batchingConfig {
            maxStopPerRoute
            dropOffServiceTime
          }
        }
      }
    }
  }
`

import gql from 'graphql-tag'

export const UpdateUserConfigGQL = gql`
  mutation (
    $userObjectId: String
    $marketplace: MarketplaceConfigInput
    $bulk4PL: Bulk4PLConfigInput
    $fixedPrice: FixedPriceConfigInput
    $priceMatrix: PriceMatrixConfigInput
    $selfManaged: UpdateConfigInput
    $pinCode: PinCodeConfigInput
    $podSignature: UpdateConfigInput
    $qrCode: QRCodeConfigInput
    $batchingTiming: BatchingTimingConfigInput
    $providerMatching: ProviderMatchingConfigInput
    $placeOrderConfig: PlaceOrderConfigInput
    $batchingOptions: BatchingOptionsInput
    $rerace: ReraceConfigInput
    $sms: SmsConfigInput
    $autoTip: AutoTipConfigInput
    $antiStacking: AntiStackingConfigInput
    $providerApiKey: ProviderApiKeyConfigInput
    $autoAssign: AutoAssignConfigInput
    $batchingConfig: BatchingConfigInput
    $aidaConfig: AidaConfigInput
    $geofencing: GeofencingConfigInput
  ) {
    updateUserConfig(
      userObjectId: $userObjectId
      bulk4PL: $bulk4PL
      fixedPrice: $fixedPrice
      priceMatrix: $priceMatrix
      selfManaged: $selfManaged
      pinCode: $pinCode
      podSignature: $podSignature
      marketplace: $marketplace
      qrCode: $qrCode
      batchingTiming: $batchingTiming
      providerMatching: $providerMatching
      placeOrderConfig: $placeOrderConfig
      batchingOptions: $batchingOptions
      geofencing: $geofencing
      rerace: $rerace
      sms: $sms
      autoTip: $autoTip
      antiStacking: $antiStacking
      providerApiKey: $providerApiKey
      autoAssign: $autoAssign
      batchingConfig: $batchingConfig
      aidaConfig: $aidaConfig
    )
  }
`
